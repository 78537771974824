import {
  GetKaeplaEventDistinctValuesOnCallParameters,
  GetKaeplaEventListOnCallParameters,
} from '../../cloudFunctions/cloudFunctions.types';
import {
  KaeplaEventAssignmentScope,
  KaeplaEventGroup,
  KaeplaEventLogLevel,
  KaeplaEventName,
} from '../../types';

import {
  ClientEventClass,
  CreateEventOptions,
  EventConfigType,
  InitializeOptions,
} from './client.event.types';

const serviceProxy = () => {
  let service: ClientEventClass | undefined;

  return {
    assignmentScope: KaeplaEventAssignmentScope,
    eventGroup: KaeplaEventGroup,
    eventName: KaeplaEventName,
    logLevel: KaeplaEventLogLevel,
    async initialize(options: InitializeOptions) {
      service = await import('./client.event.service');
      service.initialize(options);
      return service;
    },
    enable() {
      if (!service) {
        throw new Error('clientEventService not initialized');
      }

      service.enable();
    },
    disable() {
      if (!service) {
        throw new Error('clientEventService not initialized');
      }

      service.disable();
    },
    updateConfig(config: EventConfigType) {
      if (!service) {
        throw new Error('clientEventService not initialized');
      }

      service.updateConfig(config);
    },
    async createEvent(event: CreateEventOptions) {
      if (!service) {
        throw new Error('clientEventService not initialized');
      }

      return service.createEvent(event);
    },
    async getEventList(options: GetKaeplaEventListOnCallParameters) {
      if (!service) {
        throw new Error('clientEventService not initialized');
      }

      return service.getEventList(options);
    },
    async getDistinctValues(options: GetKaeplaEventDistinctValuesOnCallParameters) {
      if (!service) {
        throw new Error('clientEventService not initialized');
      }

      return service.getDistinctValues(options);
    },
  };
};

export const clientEventService = serviceProxy();
