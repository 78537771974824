import { FirebaseOptions } from 'firebase/app';

export const kaeplaFirebaseConfig = () => ({
  apiKey: 'AIzaSyCu85llAKljt6CDV6kqaTAmK6FM8Sg6iKs',
  authDomain: 'kaepla-baa7d.firebaseapp.com',
  projectId: 'kaepla-baa7d',
  storageBucket: 'kaepla-baa7d.appspot.com',
  messagingSenderId: '989254974348',
  appId: '1:989254974348:web:06ea0433c5e1a3293634a9',
}) satisfies FirebaseOptions;
