interface LogErrorOptions {
  error: Error;
  context: { path: string };
}

interface WatcherEntry {
  path?: string;
  unsubscribe: () => void;
}

export interface BaseWatcherServiceOptions {
  allowedDuplicatePaths?: string[];
  isDev?: boolean;
  logError?: (options: LogErrorOptions) => void;
}

export class BaseWatcherService {
  // watchers
  readonly watchers = new Map<string, WatcherEntry>();
  private subscribedPaths = new Set<string>();

  private allowedDuplicatePaths: string[] = [];
  private isDev = false;
  private _logError?: (options: LogErrorOptions) => void;

  constructor({ allowedDuplicatePaths, isDev, logError }: BaseWatcherServiceOptions) {
    this.allowedDuplicatePaths = allowedDuplicatePaths ?? [];
    this.isDev = isDev === true ? true : false;
    this._logError = logError;
  }

  getSubscriptionKeys = () => {
    return Array.from(this.watchers.keys());
  };

  hasSubscription = (key: string) => {
    return this.watchers.has(key);
  };

  logError = (options: LogErrorOptions) => {
    if (this._logError) {
      this._logError(options);
    }
  };

  subscribe = ({
    unsubscribe,
    key,
    path,
  }: {
    unsubscribe: () => void;
    key: string;
    path?: string;
  }) => {
    const subscribeKey = key;
    this.watchers.set(subscribeKey, { unsubscribe, path });

    return subscribeKey;
  };

  /**
   * unsubscribe single watcher
   */
  unsubscribe = (key: string) => {
    const disposer = this.watchers.get(key);
    if (disposer) {
      disposer.unsubscribe();

      if (disposer.path) {
        this.subscribedPaths.delete(disposer.path);
      }

      this.watchers.delete(key);
    }
  };

  unsubscribeAll = () => {
    this.watchers.forEach(({ unsubscribe }) => {
      unsubscribe();
    });

    this.watchers.clear();
    this.subscribedPaths.clear();
  };

  _checkIfPathIsDuplicate = (path: string) => {
    if (this.subscribedPaths.has(path)) {
      // don't say anything if path is allowed to be duplicate
      if (this.allowedDuplicatePaths.some((allowed) => path.startsWith(allowed))) {
        return;
      }

      if (this.isDev) {
        throw new Error(`Path ${path} has already been subscribed to!`);
      } else if (this._logError) {
        this._logError({
          error: new Error(`Path ${path} has already been subscribed to!`),
          context: { path },
        });
      }
    }

    this.subscribedPaths.add(path);
  };
}
