import { isAfter, isValid, parseISO } from 'date-fns';
import { z } from 'zod';

import { kaeplaEventAssignmentScopeSchema } from './KaeplaEventAssignmentScope.types';
import { kaeplaEventGroupSchema } from './KaeplaEventGroup.types';
import { kaeplaEventLogLevelSchema } from './KaeplaEventLogLevel.types';
import { kaeplaEventNameSchema } from './KaeplaEventName.types';

export const kaeplaEventFilterSchema = z
  .object({
    dateStart: z.union([z.string().datetime(), z.string().date()]).optional().nullable(),
    dateEnd: z.union([z.string().datetime(), z.string().date()]).optional().nullable(),

    originType: z.enum(['webApp', 'serverFunction']).optional().nullable(),
    accessDomain: z.string().min(1).optional().nullable(),

    assignmentScope: kaeplaEventAssignmentScopeSchema.optional().nullable(),
    eventName: kaeplaEventNameSchema.optional().nullable(),
    eventGroup: kaeplaEventGroupSchema.optional().nullable(),
    logLevel: z.array(kaeplaEventLogLevelSchema).optional().nullable(),
    uid: z.string().min(1).optional().nullable(),

    customerId: z.string().min(1).optional().nullable(),
    perspectiveId: z.string().min(1).optional().nullable(),
    projectId: z.string().min(1).optional().nullable(),
    resellerId: z.string().min(1).optional().nullable(),
    scopePath: z.array(z.string().min(1)).optional().nullable(),
    scopePathStringified: z.string().min(1).optional().nullable(),
    simulationId: z.string().min(1).optional().nullable(),
  })
  .superRefine((input, context) => {
    let valid = true;
    if (input.dateStart !== undefined && input.dateStart !== null) {
      const parsed = parseISO(input.dateStart);
      if (!isValid(parsed)) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'dateStart must be a valid ISO string',
          path: ['dateStart'],
        });
        valid = false;
      }
    }

    if (input.dateEnd !== undefined && input.dateEnd !== null) {
      const parsed = parseISO(input.dateEnd);
      if (!isValid(parsed)) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'dateEnd must be a valid ISO string',
          path: ['dateEnd'],
        });
        valid = false;
      }
    }

    if (!valid) {
      return false;
    }

    // dateStart must be before dateEnd
    if (
      input.dateStart !== undefined &&
      input.dateStart !== null &&
      input.dateEnd !== undefined &&
      input.dateEnd !== null &&
      isAfter(parseISO(input.dateStart), parseISO(input.dateEnd))
    ) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'dateStart must be before dateEnd',
        path: ['dateStart'],
      });
      return false;
    }

    return true;
  });

export type KaeplaEventFilter = z.infer<typeof kaeplaEventFilterSchema>;
