import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { KaeplaBrandingRequest, KaeplaBranding } from '@kaepla/types';

import { requestLog } from '../../Frontend/helpers/logger.js';

interface KOptions {
  params: KaeplaBrandingRequest;
}

export const getBranding = async ({ params }: KOptions) => {
  const functions = getFunctions(getApp('kaepla'), 'europe-west3');
  const getKaeplaBranding = httpsCallable(functions, 'getKaeplaBranding');

  requestLog.log(`getKaeplaBranding({data:${JSON.stringify(params)}})`);
  const result = await getKaeplaBranding(params).catch((error) => {
    requestLog.log(`kaeplaBranding.post() ERROR`, error);
  });
  if (result) {
    return result.data as KaeplaBranding;
  }
  return false;
};
