import { IconButton, IconButtonProps, styled } from '@mui/material';

interface ExpandMoreProperties extends IconButtonProps {
  expand: boolean;
}

export const ExpandMore = styled((properties: ExpandMoreProperties) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { expand, ...other } = properties;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: expand ? 'rotate(180deg)' : 'rotate(0deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));
