import { z } from 'zod';

export enum KaeplaEventLogLevel {
  INFO = 'info',
  NOTICE = 'notice',
  WARNING = 'warning',
  ERROR = 'error',
  CRITICAL = 'critical',
  EMERGENCY = 'emergency',
}

export const kaeplaEventLogLevelSchema = z.nativeEnum(KaeplaEventLogLevel);
