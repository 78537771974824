import { ThreadMessage, Tool } from '@kaepla/types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CircularProgress, Collapse, Grid2 as Grid } from '@mui/material';
import CodeEditor from '@uiw/react-textarea-code-editor';
import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { format } from 'sql-formatter';

import { useAuth } from '../../../AuthReactProvider';
import { getAiToolsForRun } from '../../../services/firestore/getAiToolsForRun';
import { projectState } from '../../../services/recoil/nonpersistent/projectState';
import { ExpandMore } from '../../features/ExpandMore';
import { useIsVisible } from '../../helpers/useIsVisible';

export const AdminTools = ({ aiMessage }: { aiMessage: ThreadMessage }) => {
  const reference = useRef(null);
  const isVisible = useIsVisible(reference);

  const { kaeplaUser } = useAuth();

  const project = useRecoilValue(projectState);
  const [isLoading, setIsLoading] = useState(true);
  const [tools, setTools] = useState<Tool[]>([]);
  const [toggleSql, setToggleSql] = useState(false);

  const handleExpandClick = () => {
    setToggleSql(!toggleSql);
  };

  useEffect(() => {
    if (!kaeplaUser || !project) return;
    if (isVisible) {
      const loadAdminTools = async () => {
        if (!aiMessage.runId) return;
        const toolsForRun = await getAiToolsForRun({
          uid: kaeplaUser.uid,
          projectId: project.id,
          runId: aiMessage.runId,
        });
        setTools(toolsForRun);
        setIsLoading(false);
      };

      void loadAdminTools();
    }
  }, [aiMessage.runId, isVisible, kaeplaUser, project]);

  if (tools.length === 0) {
    return <Grid size={12} ref={reference} sx={{ visibility: 'hidden' }} />;
  }

  if (isLoading) {
    return (
      <Grid size={12} ref={reference}>
        {isLoading && <CircularProgress size={14} />}
      </Grid>
    );
  }

  return (
    <Grid size={12} ref={reference}>
      {tools.length} tool calls (admin only)
      <ExpandMore
        expand={toggleSql}
        aria-expanded={toggleSql}
        aria-label="show sql"
        edge="end"
        sx={{ mr: -0.8 }}
        onClick={handleExpandClick}
      >
        <ExpandMoreIcon />
      </ExpandMore>
      <Collapse in={toggleSql} timeout="auto" unmountOnExit>
        <ol>
          {tools.map((tool) => (
            <li key={tool.id}>
              {tool.input?.function.name} - {tool.id}
              {tool.input?.function.name === 'execute_query' && (
                <CodeEditor
                  value={format(
                    (
                      JSON.parse(tool.input?.function.arguments ?? '') as unknown as Record<
                        string,
                        string
                      >
                    ).sql || '',
                    {
                      language: 'bigquery',
                    },
                  )}
                  data-color-mode="light"
                  language="sql"
                  padding={15}
                  style={{
                    fontSize: 14,
                    fontFamily:
                      'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                  }}
                />
              )}
            </li>
          ))}
        </ol>
      </Collapse>
    </Grid>
  );
};
