import { ThreadMessage } from '@kaepla/types';
import { Box, Grid2 as Grid, Stack, Typography } from '@mui/material';
import Markdown from 'react-markdown';
import TimeAgo from 'react-timeago';
import { useRecoilValue } from 'recoil';
import remarkGfm from 'remark-gfm';

import { kaeplaAssignmentState } from '../../../services/recoil/persistent/kaeplaAssignmentState';
import { AdminClips } from '../Admin/AdminClips';
import { AdminTools } from '../Admin/AdminTools';

import { AiMonitoringToggle } from './AiMonitoringToggle';

export const AiMessageContent = ({ aiMessage }: { aiMessage: ThreadMessage }) => {
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  return (
    <Grid container>
      <Grid size={12}>
        {aiMessage.data.content.map((element) => (
          <Box key={element.type}>
            {element.type === 'text' && (
              <Markdown remarkPlugins={[remarkGfm]}>{element.text.value}</Markdown>
            )}
            {element.type !== 'text' && <Box>not implemented yet</Box>}
          </Box>
        ))}
        {kaeplaAssignment && <AdminTools aiMessage={aiMessage} />}
      </Grid>
      <Grid size={12}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="caption" color="textSecondary">
            <TimeAgo date={aiMessage.createdAt.toDate()} max={Number.MAX_SAFE_INTEGER} />
          </Typography>
          <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
          {kaeplaAssignment && <AdminClips aiMessage={aiMessage} />}
          <AiMonitoringToggle aiMessage={aiMessage} />
        </Stack>
      </Grid>
    </Grid>
  );
};
