import { getApp } from 'firebase/app';
import { doc, DocumentData, getFirestore, setDoc, Timestamp } from 'firebase/firestore';
import { KaeplaUserPreferences, FirestoreTimestamp } from '@kaepla/types';

import { mapUndefinedToNullValue } from './mapUndefinedToNullValue.js';

interface Options {
  preferences: Partial<KaeplaUserPreferences>;
}

export const createOrUpdateUserPreferences = async ({ preferences }: Options) => {
  if (!preferences.id) return;
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStoreDocumentPath = `userPreferences/${preferences.id}`;
  const firestoreDocumentReference = doc(firestoreDatabase, fireStoreDocumentPath);

  const update: Partial<KaeplaUserPreferences> = {
    ...preferences,
    updatedAt: Timestamp.now() as FirestoreTimestamp,
  };

  await setDoc(firestoreDocumentReference, mapUndefinedToNullValue(update) as DocumentData, {
    merge: true,
  });

  return update as KaeplaUserPreferences;
};
