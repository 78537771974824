import { FirebaseApp } from 'firebase/app';
import {
  getFirestore,
  DocumentData,
  DocumentSnapshot,
  doc,
  collection,
  onSnapshot,
  query,
  limit,
  orderBy,
  Query,
  QuerySnapshot,
  OrderByDirection,
  where,
  FieldPath,
  WhereFilterOp,
} from 'firebase/firestore';

import { BaseWatcherService, BaseWatcherServiceOptions } from './base.watcher.service';

export interface FirestoreWatcherServiceOptions extends BaseWatcherServiceOptions {
  app: FirebaseApp;
}

export interface WhereClause {
  fieldPath: string | FieldPath;
  opStr: WhereFilterOp;
  value: unknown;
}

export class FirestoreWatcherService extends BaseWatcherService {
  readonly app: FirebaseApp;

  constructor(options: FirestoreWatcherServiceOptions) {
    super({
      allowedDuplicatePaths: options.allowedDuplicatePaths,
      isDev: options.isDev,
      logError: options.logError,
    });

    this.app = options.app;
  }

  /**
   * subscribe to changes of a document
   */
  subscribeToDocument = <T extends DocumentData>({
    path,
    onSnapshot: onSnapshot_,
    onError: onError_,
    key,
  }: {
    path: string;
    onSnapshot: (querySnapshot: DocumentSnapshot<T>, key: string) => void;
    onError?: (error: Error) => boolean;
    key?: string;
  }) => {
    this._checkIfPathIsDuplicate(path);

    const subscribeKey = key ?? `${path}:${Date.now()}`;
    const reference = doc(getFirestore(this.app), path);
    const unsubscribe = onSnapshot(
      reference,
      (documentSnapshot) => onSnapshot_(documentSnapshot as DocumentSnapshot<T>, subscribeKey),
      (error) => {
        if (onError_ && !onError_(error)) {
          return;
        }

        if (this.logError) {
          this.logError({ error, context: { path } });
        }
        throw error;
      },
    );

    this.watchers.set(subscribeKey, { unsubscribe, path });

    return () => {
      this.unsubscribe(subscribeKey);
    };
  };

  /**
   * subscribe to changes of a collection
   */
  subscribeToCollection = <T extends DocumentData>({
    path,
    onSnapshot: onSnapshot_,
    onError: onError_,
    key,
    orderBy: orderBy_,
    where: wheres_,
    limit: limit_,
  }: {
    path: string;
    onSnapshot: (querySnapshot: QuerySnapshot<T>, key: string) => void;
    onError?: (error: Error) => boolean;
    key?: string;
    where?: WhereClause[];
    orderBy?: {
      fieldPath: string;
      direction: OrderByDirection;
    };
    limit?: number;
  }) => {
    this._checkIfPathIsDuplicate(path);

    const reference = collection(getFirestore(this.app), path);
    let query_ = reference as unknown as Query<T>;

    if (wheres_) {
      wheres_.forEach(({ fieldPath, opStr, value }) => {
        query_ = query(query_, where(fieldPath, opStr, value));
      });
    }

    if (orderBy_) {
      query_ = query(query_, orderBy(orderBy_.fieldPath, orderBy_.direction));
    }

    if (limit_) {
      query_ = query(query_, limit(limit_));
    }

    const subscribeKey = key ?? `${path}:${Date.now()}`;

    const unsubscribe = onSnapshot(
      query_,
      (querySnapshot) => onSnapshot_(querySnapshot, subscribeKey),
      (error) => {
        if (onError_ && !onError_(error)) {
          return;
        }

        if (this.logError) {
          this.logError({ error, context: { path } });
        }

        throw error;
      },
    );

    this.watchers.set(subscribeKey, { unsubscribe, path });

    return () => {
      this.unsubscribe(subscribeKey);
    };
  };
}
