import React from 'react';

import { useInitializeKaeplaEvents } from '../hooks/useInitializeKaeplaEvents';

export const KaeplaEventProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { isInitialized } = useInitializeKaeplaEvents();

  if (!isInitialized) {
    return null;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
