import { ThreadMessage } from '@kaepla/types';
import { Grid2 as Grid, Paper, useMediaQuery, useTheme } from '@mui/material';

import { CONVERSATIONAL_UI_FONTSIZE_SM, CONVERSATIONAL_UI_FONTSIZE_SX } from '../lib/constants';

import { AiMessageContent } from './AiMessageContent';

// eslint-disable-next-line import/no-unassigned-import
import './style.css';

export const AssistantMessage = ({ aiMessage }: { aiMessage: ThreadMessage }) => {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Grid container spacing={2}>
      <Grid size={12}>
        <Paper
          elevation={0}
          sx={{
            pb: 1,
            px: 2,
            mt: 1,
            whiteSpace: 'pre-wrap',
            fontFamily: 'monospace',
            fontSize: smUp ? CONVERSATIONAL_UI_FONTSIZE_SM : CONVERSATIONAL_UI_FONTSIZE_SX,
          }}
        >
          <AiMessageContent aiMessage={aiMessage} />
        </Paper>
      </Grid>
    </Grid>
  );
};
