import {
  amber,
  blue,
  brown,
  cyan,
  deepOrange,
  grey,
  indigo,
  lightGreen,
  pink,
  purple,
  red,
  teal,
} from '@mui/material/colors';
import { colord } from 'colord';

import { infoColor, mainColor, secondaryColor, snapshotColor } from '../Theme/colors.js';

export const avatarSize = 150;
export const maxAvatarsDefault = 4;
export const scrollStyle = {
  display: 'flex',
  overflowX: 'scroll',
};
export const avatarGroupSlot = {
  additionalAvatar: {
    sx: {
      bgcolor: grey[300],
      color: grey[600],
      fontSize: 16,
      width: 45,
      height: 45,
      cursor: 'pointer',
    },
  },
};

export const avatarAppearDuration = 10;
export const matrixDataColorLight = colord(mainColor).alpha(0.3).toRgbString();
export const simulationDataColorLight = colord(secondaryColor).alpha(0.3).toRgbString();
export const simulationDataColorShade = colord(secondaryColor).alpha(0.03).toRgbString();
export const simulationDataUpperLight = colord(secondaryColor).alpha(0.5).toRgbString();
export const simulationDataLowerLight = colord(secondaryColor).alpha(0.3).toRgbString();
export const snapshotDataColorLight = colord(snapshotColor).alpha(0.3).toRgbString();
export const targetsDataColorLight = colord(infoColor).alpha(0.05).toRgbString();
export const targetsDataColorUpperLight = colord(infoColor).alpha(0.5).toRgbString();
export const targetsDataColorLowerLight = colord(infoColor).alpha(0.3).toRgbString();
export const simulationPreviewDataColor = 'rgba(39, 39, 39, 0.5)';
export const simulationPreviewDataColorLight = 'rgba(39, 39, 39, 0.2)';
export const simulationPreviewDataColorDM = 'rgba(220, 220, 220, 0.5)';
export const simulationPreviewDataColorLightDM = 'rgba(220, 220, 220, 0.2)';

export {
  mainColor as matrixDataColor,
  secondaryColor as simulationDataColor,
  infoColor as targetsDataColor,
} from '../Theme/colors';

export const importColor = teal[300];
export const exportColor = blue[500];
export const validateColor = red[300];
export const alignmentsColor = grey[300];
export const assignmentsColor = cyan[500];
export const customersColor = grey[300];
export const eventsColor = lightGreen[400];
export const operationsColor = brown[200];
export const experimentalAiColor = indigo[300];
export const perspectivesColor = deepOrange[300];
export const projectsColor = blue[300];
export const resellersColor = grey[300];
export const scopesColor = pink[200];
export const simulationsColor = amber[700];
export const targetsColor = purple[200];

export { snapshotColor as dataSnapshotColor } from '../Theme/colors.js';
