import {
  toggleAppLoadingLog,
  toggleLayoutLog,
  toggleMfaLog,
  toggleParseErrorLog,
  toggleRequestLog,
  toggleToDoLog,
} from './Frontend/helpers/logger';

toggleAppLoadingLog(true);
toggleLayoutLog(false);
toggleRequestLog(true);
toggleMfaLog(false);
// we want to see them always
toggleParseErrorLog(false);
toggleToDoLog(false);
