import { useRecoilValue } from 'recoil';

import { kaeplaAssignmentState } from '../../services/recoil/persistent/kaeplaAssignmentState.js';
import { logger } from '../helpers/logger.js';

import { CustomerAssignmentsListenerKaeplaAdmins } from './CustomerAssignmentsListenerKaeplaAdmins.js';
import { CustomerAssignmentsListenerUsers } from './CustomerAssignmentsListenerUsers.js';

export const CustomerAssignmentsListener = () => {
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);

  logger.log('CustomerAssignmentsListener renders');

  if (kaeplaAssignment) {
    return <CustomerAssignmentsListenerKaeplaAdmins />;
  }

  return <CustomerAssignmentsListenerUsers />;
};
