import { atom } from 'recoil';
import { KaeplaUser } from '@kaepla/types';

import { localForageEffect } from './localForageEffect.js';

export const knownUsersState = atom<KaeplaUser[]>({
  key: 'knownUsers',
  default: [],
  // eslint-disable-next-line @typescript-eslint/naming-convention
  effects_UNSTABLE: [localForageEffect],
});
