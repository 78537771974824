import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { useAuth } from '../../AuthReactProvider.js';
import { applicationState } from '../../services/recoil/nonpersistent/applicationState.js';
import { knownCustomersState } from '../../services/recoil/nonpersistent/knownCustomersState.js';
import { knownProjectsState } from '../../services/recoil/nonpersistent/knownProjectsState.js';
import { projectState } from '../../services/recoil/nonpersistent/projectState.js';
import { projectsOverviewState } from '../../services/recoil/nonpersistent/projectsOverviewState.js';
import { selectedCustomerState } from '../../services/recoil/nonpersistent/selectedCustomer.js';
import { currentScopePathState } from '../../services/recoil/persistent/currentScopePathState.js';
import { lastCustomerIDState } from '../../services/recoil/persistent/lastCustomerState';
import { lastProjectIdState } from '../../services/recoil/persistent/lastProjectState.js';
import { logger } from '../helpers/logger.js';

export const LastProjectAndCustomer = () => {
  const { kaeplaUser } = useAuth();
  const lastProject = useRecoilValue(lastProjectIdState);
  const lastCustomer = useRecoilValue(lastCustomerIDState);
  const knownProjects = useRecoilValue(knownProjectsState);
  const knownCustomers = useRecoilValue(knownCustomersState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const setProject = useSetRecoilState(projectState);
  const setSelectedCustomer = useSetRecoilState(selectedCustomerState);
  const setProjectsOverview = useSetRecoilState(projectsOverviewState);
  const [app, setApp] = useRecoilState(applicationState);
  const projectId = globalThis.location.pathname.split('/')[2];

  // For non-admins, only when app is not initialized
  useEffect(() => {
    if (!kaeplaUser?.uid) return;
    if (!app.projectsLoaded || !app.customersLoaded) return;
    if (app.initialized) return;

    let selectedCustomer: string | undefined;
    let projectsForOverview = knownProjects;

    logger.log('\nuseEffect LastProjectAndCustomer');
    logger.log('LastProjectAndCustomer currentScopePath', currentScopePath);

    logger.log(
      'LastProjectAndCustomer knownProjects',
      knownProjects.map((k) => k.id),
    );

    logger.log(
      'LastProjectAndCustomer knownCustomers',
      knownCustomers.map((k) => k.id),
    );

    if (knownProjects && knownProjects.length > 0) {
      // if we have known projects, we check if we still have the last we visited and set it

      const project = knownProjects.find((k) => k.id === lastProject);
      const projectFromUrlParameter = knownProjects.find((k) => k.id === projectId);
      if (projectId && projectFromUrlParameter?.id) {
        logger.log(
          'LastProjectAndCustomer setProject from url param',
          projectId,
          projectFromUrlParameter?.id,
        );
        setProject(projectFromUrlParameter);
      } else if (lastProject && project?.id) {
        logger.log('LastProjectAndCustomer setProject from lastProject', lastProject, project?.id);
        selectedCustomer = project.customerId;
        setProject(project);
      } else {
        logger.log('LastProjectAndCustomer setProject from knownProjects[0]', knownProjects[0]?.id);
        selectedCustomer = knownProjects[0].customerId;
        setProject(knownProjects[0]);
      }

      // this is only for users who have more than 1 customer assignments
      if (knownCustomers.length > 0) {
        const customer = knownCustomers.find((k) => k.id === selectedCustomer);
        logger.log(
          'LastProjectAndCustomer setSelectedCustomer from lastProject',
          selectedCustomer,
          customer?.id,
        );
        if (selectedCustomer && customer?.id) {
          projectsForOverview = knownProjects.filter((k) => k.customerId === customer.id);
          selectedCustomer = customer.id;
        }
      }
    }

    // if we couldn't get the last customer from the last project
    // maybe we can use the local state
    if (!selectedCustomer && lastCustomer && knownCustomers && knownCustomers.length > 0) {
      const customer = knownCustomers.find((k) => k.id === lastCustomer);
      if (customer) {
        logger.log(
          'LastProjectAndCustomer setSelectedCustomer from lastVisitedCustomerId',
          lastCustomer,
          customer?.id,
        );
        selectedCustomer = customer.id;
        projectsForOverview = knownProjects.filter((k) => k.customerId === customer.id);
      }
    }

    // this is for users who have a customer assignment but no project yet
    if (knownCustomers.length > 0 && !selectedCustomer) {
      selectedCustomer = knownCustomers[0].id;
      projectsForOverview = knownProjects.filter((k) => k.customerId === knownCustomers[0].id);
    }

    setSelectedCustomer(knownCustomers.find((k) => k.id === selectedCustomer));
    setProjectsOverview(projectsForOverview);
    setApp({ ...app, initialized: true });
  }, [
    app,
    kaeplaUser?.uid,
    knownProjects,
    knownCustomers,
    lastProject,
    setApp,
    setProject,
    setSelectedCustomer,
    setProjectsOverview,
    currentScopePath,
    lastCustomer,
    projectId,
  ]);

  return null;
};
