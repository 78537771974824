import { useEffect } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { KaeplaAssignment } from '@kaepla/types';

import { useAuth } from '../../AuthReactProvider.js';
import { addFirestoreDocumentListener } from '../../services/firestore/addFirestoreDocumentListener.js';
import { applicationState } from '../../services/recoil/nonpersistent/applicationState.js';
import { kaeplaAssignmentState } from '../../services/recoil/persistent/kaeplaAssignmentState.js';
import { logger } from '../helpers/logger.js';

export const KaeplaAssignmentsListener = () => {
  const { kaeplaUser } = useAuth();
  const app = useRecoilValue(applicationState);
  const setKaeplaAssignment = useSetRecoilState(kaeplaAssignmentState);
  const resetKaeplaAssignment = useResetRecoilState(kaeplaAssignmentState);

  useEffect(() => {
    if (!kaeplaUser?.uid) return;
    if (app.connecting) return; // we are not connected yet

    logger.log('\nuseEffect KaeplaAssignmentsListener');

    const fireStorePath = `kaeplaAssignments/${kaeplaUser.uid}`;

    const unsubscribe = addFirestoreDocumentListener({
      fireStorePath,
      callback: (_kaeplaAssignments) => {
        const kaeplaAssignment = _kaeplaAssignments as KaeplaAssignment;
        logger.log('KaeplaAssignmentsListener fires:', kaeplaAssignment);
        if (!kaeplaAssignment) {
          resetKaeplaAssignment();
          return;
        }
        setKaeplaAssignment(kaeplaAssignment);
      },
    });
    return () => {
      logger.log('KaeplaAssignmentsListener is unmounted!');
      unsubscribe();
    };
  }, [app.connecting, kaeplaUser?.uid, resetKaeplaAssignment, setKaeplaAssignment]);

  return null;
};
