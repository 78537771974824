import { Run } from '@kaepla/types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  CircularProgress,
  Collapse,
  Grid2 as Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { useAuth } from '../../../AuthReactProvider';
import { getAiRunsForThread } from '../../../services/firestore/getAiRunsForThread';
import { projectState } from '../../../services/recoil/nonpersistent/projectState';
import { kaeplaAssignmentState } from '../../../services/recoil/persistent/kaeplaAssignmentState';
import { ExpandMore } from '../../features/ExpandMore';

export const AdminStats = ({ threadId }: { threadId?: string }) => {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const { kaeplaUser } = useAuth();
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const project = useRecoilValue(projectState);
  const [isLoading, setIsLoading] = useState(true);
  const [runs, setRuns] = useState<Run[]>([]);
  const [toggleSql, setToggleSql] = useState(false);

  const handleExpandClick = () => {
    setToggleSql(!toggleSql);
  };

  useEffect(() => {
    if (!kaeplaUser || !project || !threadId) return;
    const loadAdminRuns = async () => {
      const runsForThread = await getAiRunsForThread({
        uid: kaeplaUser.uid,
        projectId: project.id,
        threadId,
      });
      const runsSorted = runsForThread.sort(
        (a, b) => a.createdAt.toMillis() - b.createdAt.toMillis(),
      );
      setRuns(runsSorted);
      setIsLoading(false);
    };

    void loadAdminRuns();
  }, [kaeplaUser, project, threadId, toggleSql]);

  if (!kaeplaAssignment || !smUp) {
    return null;
  }

  if (isLoading) {
    return <Grid size={12}>{isLoading && <CircularProgress size={14} />}</Grid>;
  }

  return (
    <Grid size={12}>
      token stats (admin only)
      <ExpandMore
        expand={toggleSql}
        aria-expanded={toggleSql}
        aria-label="show sql"
        edge="end"
        sx={{ mr: -0.8 }}
        onClick={handleExpandClick}
      >
        <ExpandMoreIcon />
      </ExpandMore>
      <Collapse in={toggleSql} timeout="auto" unmountOnExit>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>run - tokens</TableCell>
                <TableCell align="right">completion</TableCell>
                <TableCell align="right">prompt</TableCell>
                <TableCell align="right">total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {runs.map((run) => (
                <TableRow key={run.id}>
                  <TableCell>{run.id}</TableCell>
                  <TableCell align="right">{run.data.usage?.completion_tokens}</TableCell>
                  <TableCell align="right">{run.data.usage?.prompt_tokens}</TableCell>
                  <TableCell align="right">{run.data.usage?.total_tokens}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </Grid>
  );
};
