import { ThreadMessage } from '@kaepla/types';
import { Grid2 as Grid, Paper, useMediaQuery, useTheme } from '@mui/material';
import { lightBlue } from '@mui/material/colors';

import { CONVERSATIONAL_UI_FONTSIZE_SM, CONVERSATIONAL_UI_FONTSIZE_SX } from '../lib/constants';

import { AiMessageContent } from './AiMessageContent';

export const UserMessage = ({ aiMessage }: { aiMessage: ThreadMessage }) => {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Grid container spacing={2}>
      <Grid size={2} />
      <Grid size={10}>
        <Paper
          elevation={0}
          sx={{
            py: 1,
            my: 1,
            px: 2,
            backgroundColor: lightBlue[50],
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fontSize: smUp ? CONVERSATIONAL_UI_FONTSIZE_SM : CONVERSATIONAL_UI_FONTSIZE_SX,
          }}
        >
          <AiMessageContent aiMessage={aiMessage} />
        </Paper>
      </Grid>
    </Grid>
  );
};
