import { getCustomers } from '../../services/firestore/getCustomers.js';

import { logger } from './logger.js';

interface Options {
  customerIds: string[];
}

export const getCustomersFromCustomerIds = async ({ customerIds }: Options) => {
  if (customerIds.length === 0) return [];
  logger.log('getCustomersFromCustomerIds customerIds', customerIds);
  const uniqueCustomerIds = customerIds.filter((v, index, a) => a.indexOf(v) === index);
  logger.log('getCustomersFromCustomerIds uniqueCustomerIds', uniqueCustomerIds);
  const knownCustomersFromServer = await getCustomers({ customerIds: uniqueCustomerIds });
  const cleanKnownCustomers = knownCustomersFromServer
    .filter((c) => c !== undefined)
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name));
  return cleanKnownCustomers;
};
