import { Grid2 as Grid, Paper, Typography } from '@mui/material';
import { lightBlue } from '@mui/material/colors';

import { Gap } from '../Assistant/AIAssistantConversation';

export const NewUserMessage = ({ message }: { message: string }) => {
  if (message === '') return null;
  return (
    <Grid size={12}>
      <Gap />
      <Grid container spacing={2}>
        <Grid size={2} />
        <Grid size={10}>
          <Paper elevation={0} sx={{ py: 1, my: 1, px: 2, backgroundColor: lightBlue[50] }}>
            <Grid container>
              <Grid size={12}>{message}</Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid size={12}>
          <Typography variant="caption" color="textSecondary">
            now
          </Typography>
        </Grid>
      </Grid>
      <Gap />
    </Grid>
  );
};
