import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { KaeplaUser } from '@kaepla/types';

import { useAuth } from '../../AuthReactProvider.js';
import { addFirestoreCollectionListener } from '../../services/firestore/addFirestoreCollectionListener.js';
import { kaeplaAssignmentState } from '../../services/recoil/persistent/kaeplaAssignmentState.js';
import { knownUsersState } from '../../services/recoil/persistent/knownUsersState.js';

export const UserListenerKaeplaAdmin = () => {
  const { kaeplaUser } = useAuth();
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const setKnownUsers = useSetRecoilState(knownUsersState);

  useEffect(() => {
    if (!kaeplaUser?.uid || !kaeplaAssignment) return;
    const fireStorePath = `users`;
    const unsubscribe = addFirestoreCollectionListener({
      fireStorePath,
      callback: (_users) => {
        if (_users) {
          const users = _users as KaeplaUser[];
          setKnownUsers(users);
        }
      },
    });
    return () => {
      unsubscribe();
    };
  }, [kaeplaAssignment, kaeplaUser?.uid, setKnownUsers]);

  return null;
};
