// import { IServerSideGetRowsRequest } from 'ag-grid-community';
import { z } from 'zod';

const columnVOSchema = z.object({
  id: z.string(),
  displayName: z.string(),
  field: z.string().optional().nullable(),
  aggFunc: z.string().optional().nullable(),
});

const sortModelSchema = z.object({
  colId: z.string(),
  sort: z.enum(['asc', 'desc']),
});

const groupKeysSchema = z.array(z.union([z.number(), z.string(), z.boolean()]));

const filterModelSchema = z.object({});

export const kaeplaDataGridRequestParametersSchema = z.object({
  startRow: z.number().optional().nullable(),
  endRow: z.number().optional().nullable(),
  rowGroupCols: z.array(columnVOSchema),
  valueCols: z.array(columnVOSchema),
  pivotCols: z.array(columnVOSchema),
  pivotMode: z.boolean(),
  groupKeys: groupKeysSchema,
  filterModel: filterModelSchema.nullable(),
  sortModel: z.array(sortModelSchema),
});

export type KaeplaDataGridRequestParameters = z.infer<typeof kaeplaDataGridRequestParametersSchema>;
