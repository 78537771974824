import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { KaeplaCustomerAssignment } from '@kaepla/types';

import { addFirestoreCollectionListener } from '../../services/firestore/addFirestoreCollectionListener.js';
import { applicationState } from '../../services/recoil/nonpersistent/applicationState.js';
import { customerAssignmentState } from '../../services/recoil/nonpersistent/customerAssignmentState.js';
import { knownCustomersState } from '../../services/recoil/nonpersistent/knownCustomersState.js';
import { getCustomersFromCustomerIds } from '../helpers/getCustomersFromCustomerIds.js';
import { logger } from '../helpers/logger.js';

export const CustomerAssignmentsListenerKaeplaAdmins = () => {
  const setApp = useSetRecoilState(applicationState);
  const setKnownCustomers = useSetRecoilState(knownCustomersState);
  const setCustomerAssignments = useSetRecoilState(customerAssignmentState);

  useEffect(() => {
    // no additional checks here because:
    // we know that app.connecting isn't true any more,
    // as we set kaeplaAssignment after connecting
    // and this component only loads if we have a kaeplaAssignment

    logger.log('\nuseEffect CustomerAssignmentsListenerKaeplaAdmins');

    const fireStorePath = `customerAssignments`;

    const unsubscribe = addFirestoreCollectionListener({
      fireStorePath,
      callback: (data) => {
        const assignments = data as KaeplaCustomerAssignment[];
        logger.log('CustomerAssignmentsListenerKaeplaAdmins fires:', data);

        const loadCustomers = async () => {
          const customerIds = assignments.map((c) => c.customerId);
          const customers = await getCustomersFromCustomerIds({ customerIds });
          setCustomerAssignments(assignments);
          setKnownCustomers(customers);
          setApp((previousApp) => ({ ...previousApp, customersLoaded: true }));
        };
        void loadCustomers();
      },
    });
    return () => {
      logger.log('CustomerAssignmentsListenerKaeplaAdmins is unmounted!');
      unsubscribe();
    };
  }, [setApp, setCustomerAssignments, setKnownCustomers]);

  return null;
};
