import { clientEventService } from '@kaepla/events';
import { useEffect, useState } from 'react';

import packageJson from '../../package.json';
import { firebaseApp } from '../firebaseInit';
import { logger } from '../Frontend/helpers/logger';
import { IS_CI, IS_DEV } from '../mode';

export const useInitializeKaeplaEvents = () => {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    void clientEventService
      .initialize({
        firebaseApp,
        isCreateEnabled: !IS_DEV && !IS_CI,
        logger,
        version: packageJson.version,
      })
      .then(() => {
        setIsInitialized(true);
      });
  }, []);

  return { isInitialized };
};
