import { KaeplaUser, FirestoreTimestamp } from '@kaepla/types';
import { getApp } from 'firebase/app';
import { doc, DocumentData, getFirestore, setDoc, Timestamp } from 'firebase/firestore';

interface Options {
  user: Partial<KaeplaUser>;
}

export const createOrUpdateUser = async ({ user }: Options) => {
  if (!user.uid) return;
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `users/${user.uid}`;
  const firestoreDocumentReference = doc(firestoreDatabase, fireStorePath);

  const update: Partial<KaeplaUser> = {
    ...user,
    updatedAt: Timestamp.now() as FirestoreTimestamp,
  };
  await setDoc(firestoreDocumentReference, update as DocumentData, {
    merge: true,
  });
  return update as KaeplaUser;
};
