import { getApp } from 'firebase/app';
import { getFirestore, collection, query, getDocs, where } from 'firebase/firestore';
import { Tool } from '@kaepla/types';

interface Options {
  uid: string;
  projectId: string;
  runId: string;
}

export const getAiToolsForRun = async ({ uid, runId, projectId }: Options) => {
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `aiToolCalls`;
  const firestoreCollection = collection(firestoreDatabase, fireStorePath);
  const firestoreQuery = query(
    firestoreCollection,
    where('uid', '==', uid),
    where('projectId', '==', projectId),
    where('runId', '==', runId),
  );
  const snapshot = await getDocs(firestoreQuery);
  if (!snapshot) return [];
  return snapshot.docs.map((document_) => document_.data() as Tool);
};
