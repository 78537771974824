import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Box,
  Button,
  Divider,
  Grid2 as Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { red } from '@mui/material/colors';
import { OAuthProvider } from 'firebase/auth';
import Favicon from 'react-favicon';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import packageJson from '../../../../../package.json';
import { useAuth } from '../../../../AuthReactProvider';
import { documentTitle } from '../../../../Frontend/helpers/documentTitle';
import { Image } from '../../../../primitive/Image/Image';
import { brandingState } from '../../../../services/recoil/persistent/brandingState';
import { SWUpdater } from '../../../features/ServiceWorkerUpdater';
import { isBeta } from '../../../helpers/isBeta';
import { AuthProviderIcon } from '../../User/features/AuthProviders/AuthProviderIcon';
import { oAuth2Providers } from '../../User/features/AuthProviders/oAuth2Providers';

import { EmailLoginOptions } from './EmailLoginOptions';

export const MoreLoginOptions = () => {
  const {
    signInWithOauthRedirect,
    authError,
    clearAuthError,
    authProcedureError,
    clearAuthProcedureError,
  } = useAuth();
  const navigate = useNavigate();
  const branding = useRecoilValue(brandingState);
  const samlProvider = branding?.authenticationConfiguration?.configuration.samlProvider;
  const providerPlatform = branding?.authenticationConfiguration?.configuration.providerPlatform;

  return (
    <Box
      sx={{
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#EEEEEE',
      }}
    >
      {branding?.appIconBase64 && <Favicon url={branding?.appIconBase64} />}
      <SWUpdater />
      <Paper
        sx={{ padding: 5, minWidth: 350, maxWidth: 400 }}
        data-testid="login-BrandedLoginOptionsPaper"
      >
        <Grid container spacing={3} data-testid="login-BrandedLogin" alignItems="center">
          {branding?.appLogoUrl && (
            <Grid size={12} container justifyContent="center">
              <Image
                data-testid="login-BrandedLoginImage"
                width={200}
                alt={documentTitle(branding)}
                src={branding?.appLogoUrl}
              />
            </Grid>
          )}
          {authError && (
            <Grid size={12}>
              <Alert
                variant="outlined"
                severity="error"
                data-testid="login-BrandedLoginErrorAlert"
                action={
                  <IconButton
                    aria-label="close"
                    data-testid="login-BrandedLoginErrorAlertClose"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      clearAuthError();
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {authError.code}
              </Alert>
            </Grid>
          )}
          {authProcedureError && (
            <Grid size={12}>
              <Alert
                variant="outlined"
                severity="error"
                data-testid="login-BrandedLoginErrorAlert"
                action={
                  <IconButton
                    aria-label="close"
                    data-testid="login-BrandedLoginErrorAlertClose"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      clearAuthProcedureError();
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {authProcedureError}
              </Alert>
            </Grid>
          )}
          {samlProvider && providerPlatform && (
            <Grid size={12}>
              <EmailLoginOptions />
            </Grid>
          )}
          {samlProvider && providerPlatform && (
            <Grid size={12}>
              <Divider />
            </Grid>
          )}
          <Grid size={12}>
            {oAuth2Providers
              // .filter((p) => p.active === true)
              .map((provider) => {
                return (
                  <Box sx={{ m: 1 }} key={provider.providerId}>
                    <Stack direction="row" justifyContent="space-between">
                      <Box sx={{ m: 0.5 }}>
                        <AuthProviderIcon providerId={provider.providerId} />
                      </Box>
                      <Button
                        size="small"
                        data-testid={`login-OAuth-${provider.providerId}`}
                        disabled={provider.active === false}
                        onClick={() => {
                          const oAuthProvider = new OAuthProvider(provider.providerId);
                          void signInWithOauthRedirect(oAuthProvider);
                        }}
                      >
                        Login with {provider.label}
                      </Button>
                    </Stack>
                  </Box>
                );
              })}
          </Grid>
          <Grid size={12}>
            <Divider />
          </Grid>
          <Grid size={12}>
            <Box sx={{ textAlign: 'center', pt: 2 }}>
              <Button
                variant="text"
                data-testid="login-LoginBackToStandardLogin"
                onClick={() => {
                  clearAuthError();
                  clearAuthProcedureError();
                  void navigate(`/`);
                }}
              >
                back to standard login
              </Button>
            </Box>
          </Grid>
          <Grid size={12}>
            <Typography component="div" variant="caption" sx={{ textAlign: 'center', pt: 2 }}>
              {isBeta() && (
                <Box component="span" sx={{ color: red[500], fontSize: 14, mr: 1 }}>
                  β
                </Box>
              )}
              {packageJson.version}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};
