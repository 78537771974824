import { WhereClause } from '@atrigam/atrigam-service-firebase-watcher';
import { Timestamp } from 'firebase/firestore';
import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { KaeplaFilter } from '@kaepla/types';

import { useAuth } from '../../AuthReactProvider';
import { addFirestoreCollectionListener } from '../../services/firestore/addFirestoreCollectionListener';
import { applicationState } from '../../services/recoil/nonpersistent/applicationState';
import { knownProjectFiltersState } from '../../services/recoil/nonpersistent/knownProjectFiltersState';
import { projectState } from '../../services/recoil/nonpersistent/projectState';
import { currentScopePathState } from '../../services/recoil/persistent/currentScopePathState';
import { logger, requestLog } from '../helpers/logger.js';

export const FiltersListener = () => {
  const { kaeplaUser } = useAuth();
  const project = useRecoilValue(projectState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const setKnownProjectFilters = useSetRecoilState(knownProjectFiltersState);
  const setApp = useSetRecoilState(applicationState);

  // this is the firestore targetsListener
  useEffect(() => {
    if (!kaeplaUser?.uid) return;
    if (!project?.id) return;
    if (!currentScopePath) return;
    logger.log('\nuseEffect FiltersListener');

    const fireStorePath = `projects/${project.id}/filters`;
    const queryWhere: WhereClause[] = [];
    queryWhere.push({
      fieldPath: 'scopePathStringified',
      opStr: '==',
      value: JSON.stringify(currentScopePath),
    });

    const unsubscribe = addFirestoreCollectionListener({
      fireStorePath,
      queryWhere,
      callback: (dataFromServer) => {
        const filtersFromServer = dataFromServer as KaeplaFilter<Timestamp>[];
        requestLog.log(`FiltersListener filtersFromServer`, filtersFromServer);
        setKnownProjectFilters(filtersFromServer);
        setApp((app) => ({ ...app, filtersListenerActive: true }));
      },
    });

    return () => {
      requestLog.log(`unsubscribe filters Listener`);
      setApp((app) => ({ ...app, filtersListenerActive: false }));
      unsubscribe();
    };
  }, [currentScopePath, kaeplaUser?.uid, project?.id, setApp, setKnownProjectFilters]);

  return null;
};
