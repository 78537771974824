import { z } from 'zod';

import { KaeplaEvent } from '.';

// get distinct values
export const kaeplaEventColumnSchema = z.enum([
  'originType',
  'accessDomain',
  'eventName',
  'eventGroup',
  'logLevel',
  'uid',
  'customerId',
  'perspectiveId',
  'projectId',
  'resellerId',
  'scopePathStringified',
  'simulationId',
]);

export type KaeplaEventColumn =
  | keyof Omit<KaeplaEvent, 'id' | 'dateTime' | 'origin' | 'payload' | 'scopePath'>
  // additional origin columns
  | 'originType'
  | 'accessDomain';
