import { BigQueryDatetime } from '@google-cloud/bigquery';
import { ISODateTime } from '@kaepla/types';
import { z } from 'zod';

import {
  KaeplaEventAssignmentScope,
  kaeplaEventAssignmentScopeSchema,
} from './KaeplaEventAssignmentScope.types';
import { KaeplaEventGroup, kaeplaEventGroupSchema } from './KaeplaEventGroup.types';
import { KaeplaEventLogLevel, kaeplaEventLogLevelSchema } from './KaeplaEventLogLevel.types';
import { KaeplaEventName, kaeplaEventNameSchema } from './KaeplaEventName.types';
import { KaeplaEventOrigin, kaeplaEventOriginSchema } from './KaeplaEventOrigin.types';
import { KaeplaEventPayload, kaeplaEventPayloadSchema } from './KaeplaEventPayload.types';

export interface KaeplaEvent {
  id: string;
  dateTime: ISODateTime;
  origin: KaeplaEventOrigin;
  assignmentScope: KaeplaEventAssignmentScope;
  eventName: KaeplaEventName;
  eventGroup: KaeplaEventGroup;
  logLevel: KaeplaEventLogLevel;
  payload?: KaeplaEventPayload | null;
  uid?: string | null;

  // Context Information
  customerId?: string | null;
  perspectiveId?: string | null;
  projectId?: string | null;
  resellerId?: string | null;
  scopePath?: string[] | null;
  scopePathStringified?: string | null;
  simulationId?: string | null;

  // migration data
  __firestoreId?: string | null;
}

export const kaeplaEventSchema = z
  .object({
    id: z.string(),
    origin: kaeplaEventOriginSchema,
    assignmentScope: kaeplaEventAssignmentScopeSchema,
    eventName: kaeplaEventNameSchema,
    eventGroup: kaeplaEventGroupSchema,
    logLevel: kaeplaEventLogLevelSchema,

    payload: kaeplaEventPayloadSchema.optional().nullable(),
    uid: z.string().optional().nullable(),
    customerId: z.string().optional().nullable(),
    perspectiveId: z.string().optional().nullable(),
    projectId: z.string().optional().nullable(),
    resellerId: z.string().optional().nullable(),
    scopePath: z.array(z.string()).optional().nullable(),
    simulationId: z.string().optional().nullable(),

    // migration data
    __firestoreId: z.string().optional().nullable(),
  })
  .strict();

export const createKaeplaEventSchema = kaeplaEventSchema
  .omit({
    id: true,
  })
  .partial({ logLevel: true });

export type CreateKaeplaEvent = z.infer<typeof createKaeplaEventSchema>;

export type CreateKaeplaEventMutationParameter = Omit<CreateKaeplaEvent, 'scopePathStringified'> & {
  id?: string;
  dateTime?: BigQueryDatetime;
};
