import { WhereClause } from '@atrigam/atrigam-service-firebase-watcher';
import { KaeplaDomainType, KaeplaProjectAssignment } from '@kaepla/types';
import { useCallback, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { useAuth } from '../../AuthReactProvider.js';
import { addFirestoreCollectionListener } from '../../services/firestore/addFirestoreCollectionListener.js';
import { getProjectsFromProjectIds } from '../../services/firestore/getProjectFromProjectIds.js';
import { applicationState } from '../../services/recoil/nonpersistent/applicationState.js';
import { knownProjectsState } from '../../services/recoil/nonpersistent/knownProjectsState.js';
import { projectsOverviewState } from '../../services/recoil/nonpersistent/projectsOverviewState.js';
import { selectedCustomerState } from '../../services/recoil/nonpersistent/selectedCustomer.js';
import { projectAssignmentsState } from '../../services/recoil/nonpersistent/userAssignmentState.js';
import { userRootPathsState } from '../../services/recoil/nonpersistent/userRootPathsState.js';
import { brandingState } from '../../services/recoil/persistent/brandingState.js';
import { kaeplaAssignmentState } from '../../services/recoil/persistent/kaeplaAssignmentState.js';
import { UnhandledCaseError } from '../helpers/UnhandledCaseError.js';
import { getUserRootPaths } from '../helpers/getUserRootPaths.js';
import { logger } from '../helpers/logger.js';

export const ProjectAssignmentsListenerUsers = () => {
  const { kaeplaUser } = useAuth();
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const selectedCustomer = useRecoilValue(selectedCustomerState);
  const setKnownProjects = useSetRecoilState(knownProjectsState);
  const setProjectsOverview = useSetRecoilState(projectsOverviewState);
  const setProjectAssignments = useSetRecoilState(projectAssignmentsState);
  const setUserRootPaths = useSetRecoilState(userRootPathsState);
  const branding = useRecoilValue(brandingState);
  const [app, setApp] = useRecoilState(applicationState);

  const loadProjectsForAssignments = useCallback(
    async (projectAssignments: KaeplaProjectAssignment[]) => {
      setApp((previousApp) => ({ ...previousApp, projectsLoaded: false }));
      setProjectAssignments(projectAssignments);
      const userRootPaths = getUserRootPaths(projectAssignments);
      setUserRootPaths(userRootPaths);

      const projectIds = projectAssignments.map((projectAssignment) => projectAssignment.projectId);
      let projectsOrdered = await getProjectsFromProjectIds({ projectIds });
      if (!projectsOrdered) {
        setKnownProjects([]);
        setProjectsOverview([]);
        setApp((previousApp) => ({ ...previousApp, projectsLoaded: true }));
        return;
      }

      if (selectedCustomer) {
        projectsOrdered = [...projectsOrdered].filter(
          (project) => project.customerId === selectedCustomer.id,
        );
      }

      logger.log('ProjectAssignmentsListenerUsers setKnownProjects', projectsOrdered);
      setKnownProjects(projectsOrdered);
      setProjectsOverview(projectsOrdered);

      logger.log('ProjectAssignmentsListenerUsers setApp projectsLoaded => true');
      setApp((previousApp) => ({ ...previousApp, projectsLoaded: true }));
    },
    [
      selectedCustomer,
      setApp,
      setKnownProjects,
      setProjectAssignments,
      setProjectsOverview,
      setUserRootPaths,
    ],
  );

  useEffect(() => {
    if (kaeplaAssignment ?? !kaeplaUser?.uid) return; // user is an admin or not authenticated yet
    if (!branding?.realmType || !branding.appRealmId) return; // we always need a branding
    if (app.connecting) return; // we are not connected yet

    logger.log('\nuseEffect ProjectAssignmentsListenerUsers');

    const fireStorePath = `projectAssignments`;
    const queryWhere: WhereClause[] = [];
    // filter by uid for non kaepla admins
    queryWhere.push({
      fieldPath: 'uid',
      opStr: '==',
      value: kaeplaUser.uid,
    });

    logger.log('branding?.realmType', branding.realmType);

    // Branding is used for white-labelling Kaepla
    // See https://www.notion.so/White-labelling-72afc9bf76364fb7a6e0d66e8e666c95#1b0d3081a3ea40ada9e6915aae3e9c0c

    switch (branding.realmType) {
      case KaeplaDomainType.customer: {
        logger.log('query by customerId ==', branding.appRealmId);
        // customer domain lock through branding
        queryWhere.push({
          fieldPath: 'customerId',
          opStr: '==',
          value: branding.appRealmId,
        });

        break;
      }

      case KaeplaDomainType.reseller: {
        logger.log('query by resellerId ==', branding.appRealmId);
        // customer domain lock through branding
        queryWhere.push({
          fieldPath: 'resellerId',
          opStr: '==',
          value: branding.appRealmId,
        });

        break;
      }

      default: {
        throw new UnhandledCaseError(branding.realmType);
      }
    }

    queryWhere.push({
      fieldPath: 'complete',
      opStr: '==',
      value: true,
    });

    const unsubscribe = addFirestoreCollectionListener({
      fireStorePath,
      queryWhere,
      callback: (projectAssignments) => {
        logger.log('ProjectAssignmentsListenerUsers fires:', projectAssignments);
        void loadProjectsForAssignments(projectAssignments as KaeplaProjectAssignment[]);
      },
      context: 'ProjectAssignmentsListenerUsers',
    });
    return () => {
      logger.log('ProjectAssignmentsListenerUsers is unmounted!');
      setApp((previousApp) => ({ ...previousApp, projectsLoaded: false }));
      unsubscribe();
    };
  }, [
    app.connecting,
    branding,
    kaeplaUser?.uid,
    kaeplaAssignment,
    loadProjectsForAssignments,
    setApp,
  ]);

  return null;
};
