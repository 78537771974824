import { atom } from 'recoil';
import { KaeplaBranding } from '@kaepla/types';

import { localForageEffect } from './localForageEffect.js';

const defaultBrandingState: KaeplaBranding | undefined = undefined;

export const brandingState = atom<KaeplaBranding | undefined>({
  key: 'Branding',
  default: defaultBrandingState,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  effects_UNSTABLE: [localForageEffect],
});
