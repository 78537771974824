import { z } from 'zod';

export enum KaeplaEventAssignmentScope {
  PROJECT = 'project',
  CUSTOMER = 'customer',
  RESELLER = 'reseller',
  GLOBAL = 'global',
  //
  DEVELOPMENT = 'development',
}

export const kaeplaEventAssignmentScopeSchema = z.nativeEnum(KaeplaEventAssignmentScope);
