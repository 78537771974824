import {
  FirestoreWatcherService,
  FirestoreWatcherServiceOptions,
} from './firestore.watcher.service';

export const FirestoreWatcherHandler = () => {
  let service: FirestoreWatcherService | undefined;

  const initialize = (options: FirestoreWatcherServiceOptions) => {
    if (!service) {
      service = new FirestoreWatcherService(options);
    }
  };

  const get = () => {
    if (service === null || service === undefined) {
      throw new Error(`Service has not been initialized yet!`);
    }

    return service;
  };

  return { initialize, get };
};
