import { Link, Typography } from '@mui/material';
import copy from 'copy-to-clipboard';
import { ThreadMessage } from '@kaepla/types';

export const AdminClips = ({ aiMessage }: { aiMessage: ThreadMessage }) => {
  return (
    <Typography variant="caption" color="textSecondary">
      <Link
        href="#"
        underline="none"
        onClick={(event) => {
          event.preventDefault();
          copy(aiMessage.threadId);
        }}
        title={aiMessage.threadId}
      >
        T
      </Link>
      &nbsp;&nbsp;
      {
        <Link
          href="#"
          underline="none"
          onClick={(event) => {
            if (!aiMessage.runId) return;
            event.preventDefault();
            copy(aiMessage.runId);
          }}
          title={aiMessage.runId}
        >
          R
        </Link>
      }
      &nbsp;&nbsp;
      <Link
        href="#"
        underline="none"
        onClick={(event) => {
          event.preventDefault();
          copy(aiMessage.id);
        }}
        title={aiMessage.id}
      >
        M
      </Link>
    </Typography>
  );
};
