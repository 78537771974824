import { atom } from 'recoil';
import { FirestoreTimestamp } from '@kaepla/types';

interface AppState {
  connecting: boolean;
  benchmarks: boolean;
  usersLastFetched: string | null;
  showFilter: boolean;
  customersLoaded: boolean;
  projectsLoaded: boolean;
  initialized: boolean;
  pathNavigationInitialized: boolean;
  simulationListenerActive: boolean;
  targetsListenerActive: boolean;
  filtersListenerActive: boolean;
  tourLastStarted: FirestoreTimestamp | null;
}

const defaultState: AppState = {
  connecting: true,
  benchmarks: false,
  usersLastFetched: null,
  showFilter: false,
  customersLoaded: false,
  projectsLoaded: false,
  initialized: false,
  pathNavigationInitialized: false,
  simulationListenerActive: false,
  targetsListenerActive: false,
  filtersListenerActive: false,
  tourLastStarted: null,
};

export const applicationState = atom({
  key: 'application',
  default: defaultState,
});
